<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">商票易贴现</h4>
            <div class="row mt-4">
              <div style="display: flex;margin-bottom: 20px;width: 300px;">

                <el-input v-model="searchInput" placeholder="请输入可贴现的票系"></el-input>
                <el-button @click="AddMayFare">添加</el-button>
              </div>

            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table :items="tableData" :fields="fields"  responsive="sm" per-page=0 :current-page="currentPage"
                :sort-by.sync="sortBy" :no-provider-paging=false :sort-desc.sync="sortDesc" :filter="filter"
                :filter-included-fields="filterOn" @filtered="onFiltered">
                <template v-slot:cell(img_url)="data">
                  <span v-html="data.value"></span>
                </template>

                <template v-slot:cell(tool)="data">

                  <b-button @click="delRows(data.item.piaoxi,data.index)" style="margin-right: 10px;" variant="danger">删除
                  </b-button>

                </template>

              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <div class="paginationClass">
                      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="page.currentPage" :page-sizes="[10,20, 30, 40, 50]" :page-size="page.pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="page.total">
                      </el-pagination>
                    </div>
                  </ul>
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";

  export default {
    page: {
      title: "商票易贴现",
      meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader },
    data() {
      return {
        modalShow: false,
        searchInput: "",
        form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        tableData: '',
        title: "商票易贴现",
        items: [
          {
            text: "母公司管理",
            href: "/"
          },
          {
            text: "商票易贴现",
            active: true
          }
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "age",
        sortDesc: false,
        fields: [
          { key: "name", label: "集团名称" },
          { key: "piaoxi", label: "可贴现票系" },
          {
            key: "tool", label: "工具栏"
          },
        ],
        dialogVisible: false,
        EditRowData: "",
        LogoFile: "",
        page: {
          pageSize: 10,//每页显示条数
          currentPage: 1,//当前页码数
          total: 0,
        },
        Editindex: "",
        utils:""
      };
    },
    computed: {
      /**
       * Total no. of records
       */
      rows() {
        return this.tableData.length;
      }
    },
    mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length;
    },
    created() {
      this.CheckParentCompany()
      this.utils = utils
      window.addEventListener("keydown", this.handkeyCode, true); //开启监听键盘按下事件

    },
    methods: {
      // 键盘按下事件
      handkeyCode(e) {
        let key = null;
        if (window.event === undefined) {
          key = e.keyCode;
        } else {
          key = window.event.keyCode;
        }
        if (this.modalShow == true && key === 13) {
          this.onSubmit(); //登录事件
        }
      },
      // 跳转子公司
      SubCompany() {
        this.$router.push({
          name: "Index",
          // params: { teamid: teamid, keyPath: this.keyPath },
        });
      },
      AddMayFare() {
        _API_.AddFare({
          piaoxi: this.searchInput
        }).then((res) => {
          this.CheckParentCompany()

        })
      },
      handleClose() {
        this.modalShow = false
      },
      /**
       * Search the table data with search input
       */
      delRows(item,) {
        _API_.GetFareList({
          piaoxi: item,
        }).then((res) => {
          this.CheckParentCompany()
        })
      },
      EditRows(Item, Editindex) {
        this.modalShow = true
        this.Editindex = Editindex
        Item.Listed_of = Item.Listed_of == 1 ? true : false
        console.log(Item.Listed_of)
        this.EditRowData = Item
      },
      ModalTrue() {
        this.modalShow = false
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      CheckParentCompany() {
        _API_.GetFareList({
          page: this.page.currentPage,
          size: this.page.pageSize
        }).then((res) => {
          this.tableData = res.data
          this.page.total = res.count
        })
      },
      // 更改Size
      handleSizeChange(size) {
        this.page.currentPage = 1
        this.page.pageSize = size
        this.CheckParentCompany()
      },
      // 更改Page
      handleCurrentChange(page) {
        this.page.currentPage = page
        this.CheckParentCompany()
      }

    }
  };
</script>
<style>
  .el-form-item {
    margin-bottom: 10px
  }
</style>